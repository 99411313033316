<template>
  <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="salesLeads-container">
    <div class="title-container"><div>销售机会</div></div>
    <div class="common-Summary-container">
      <div class="Summary-item Summary-item-line">
        <div class="item-title">累计销售机会总数</div>
        <div class="item-black">{{ totalNum || "0" }}</div>
      </div>
      <div class="Summary-item ml">
        <el-tooltip class="item" effect="dark" content="统计从线索公海捞取15天仍未促成订单的销售机会（包含已流失和跟进中状态）" placement="bottom">
          <div class="item-title">15天以上未成单<img
              src="../../assets/images/question.png"
              class="tit-img"
            /></div>
        </el-tooltip>
        <div @click="
            () => {
              collect(
                'NotSingle',
                '15天以上未成单',
                [{ name: '销售机会名称', type: 'input', modelKey: 'name' },
                { name: '客户名称', type: 'input', modelKey: 'customerName' },
                { name: '销售名称', type: 'input', modelKey: 'manageName' },
                ],
                [
                  { name: '销售机会名称', colProp: ['name'] },
                  { name: '客户名称', colProp: ['customerName'] },
                  { name: '机会状态', colProp: ['stateName'] },
                  { name: '销售负责人', colProp: ['manageName'] },
                  { name: '创建时间', colProp: ['createTime'] },
                ]
              );
            }
          " class="item-num">{{ NotSingle || "0" }}</div>
      </div>
      <div class="Summary-item Summary-item-line">
        <el-tooltip class="item" effect="dark" content="统计3天以上没有新工作动态的销售机会" placement="bottom">
        <div class="item-title">3天以上无动态<img
              src="../../assets/images/question.png"
              class="tit-img"
            /></div>
        </el-tooltip>
        <div @click="
            () => {
              collect(
                'dayOutTime',
                '3天以上无动态',
                [
                  { name: '销售机会名称', type: 'input', modelKey: 'name' },
                  { name: '客户名称', type: 'input', modelKey: 'customerName' },
                  { name: '销售名称', type: 'input', modelKey: 'manageName' },
                ],
                [
                  { name: '销售机会名称', colProp: ['name'] },
                  { name: '客户名称', colProp: ['customerName'] },
                  { name: '机会状态', colProp: ['stateName'] },
                  { name: '销售负责人', colProp: ['manageName'] },
                  { name: '创建时间', colProp: ['createTime'] },
                ]
              );
            }
          " class="item-num">{{ dayOutTime || "0" }}</div>
      </div>
      <div class="Summary-item" style="align-items: center;">
        <el-select style="width: 80px;margin-left: 32px" v-model="topDate">
          <el-option label="今日" :value="1"></el-option>
          <el-option label="昨日" :value="2"></el-option>
          <el-option label="本周" :value="3"></el-option>
          <el-option label="本月" :value="5"></el-option>
        </el-select>
      </div>
      <div v-show="topDate==2" class="Summary-item">
        <div class="item-title">新增销售机会数</div>
        <div @click="
            () => {
              collect(
                'yesdayNum',
                '昨日新增销售机会数',
                [{ name: '销售机会名称', type: 'input', modelKey: 'name' },
                { name: '客户名称', type: 'input', modelKey: 'customerName' },
                { name: '销售阶段', type: 'select', modelKey: 'stage',selOpt: this.allDict['4']},
                { name: '机会状态', type: 'select', modelKey: 'state',selOpt: [{id: 0,name: '跟进中'},{id: 1,name: '已成单'},{id: 2,name: '已流失'}]}
                ],
                [
                  { name: '销售机会名称', colProp: ['name'] },
                  { name: '客户名称', colProp: ['customerName'] },
                  { name: '销售阶段', colProp: ['stageName'] },
                  { name: '机会状态', colProp: ['stateName'] },
                ]
              );
            }
          "
          class="item-num">{{ yesterdayNew || "0" }}</div>
      </div>
      <div v-show="topDate==1" class="Summary-item">
        <div class="item-title">新增销售机会数</div>
        <div @click="
            () => {
              collect(
                'todayNum',
                '今日新增销售机会数',
                [{ name: '销售机会名称', type: 'input', modelKey: 'name' },
                { name: '客户名称', type: 'input', modelKey: 'customerName' },
                { name: '销售阶段', type: 'select', modelKey: 'stage',selOpt: this.allDict['4']},
                { name: '机会状态', type: 'select', modelKey: 'state',selOpt: [{id: 0,name: '跟进中'},{id: 1,name: '已成单'},{id: 2,name: '已流失'}]}
                ],
                [
                  { name: '销售机会名称', colProp: ['name'] },
                  { name: '客户名称', colProp: ['customerName'] },
                  { name: '销售阶段', colProp: ['stageName'] },
                  { name: '机会状态', colProp: ['stateName'] },
                ]
              );
            }
          " class="item-num">{{ todayNum || "0" }}</div>
      </div>
      <div v-show="topDate==3" class="Summary-item">
        <div class="item-title">新增销售机会数</div>
        <div @click="
            () => {
              collect(
                'lastweekNum',
                '本周新增销售机会数',
                [{ name: '销售机会名称', type: 'input', modelKey: 'name' },
                { name: '客户名称', type: 'input', modelKey: 'customerName' },
                { name: '销售阶段', type: 'select', modelKey: 'stage',selOpt: this.allDict['4']},
                { name: '机会状态', type: 'select', modelKey: 'state',selOpt: [{id: 0,name: '跟进中'},{id: 1,name: '已成单'},{id: 2,name: '已流失'}]}
                ],
                [
                  { name: '销售机会名称', colProp: ['name'] },
                  { name: '客户名称', colProp: ['customerName'] },
                  { name: '销售阶段', colProp: ['stageName'] },
                  { name: '机会状态', colProp: ['stateName'] },
                ]
              );
            }
          " class="item-num">{{ weekNew || "0" }}</div>
      </div>
      <div v-show="topDate==5" class="Summary-item">
        <div class="item-title">新增销售机会数</div>
        <div @click="
            () => {
              collect(
                'monthNum',
                '本月新增销售机会数',
                [{ name: '销售机会名称', type: 'input', modelKey: 'name' },
                { name: '客户名称', type: 'input', modelKey: 'customerName' },
                { name: '销售阶段', type: 'select', modelKey: 'stage',selOpt: this.allDict['4']},
                { name: '机会状态', type: 'select', modelKey: 'state',selOpt: [{id: 0,name: '跟进中'},{id: 1,name: '已成单'},{id: 2,name: '已流失'}]}
                ],
                [
                  { name: '销售机会名称', colProp: ['name'] },
                  { name: '客户名称', colProp: ['customerName'] },
                  { name: '销售阶段', colProp: ['stageName'] },
                  { name: '机会状态', colProp: ['stateName'] },
                ]
              );
            }
          " class="item-num">{{ monthNum || "0" }}</div>
      </div>
    </div>
    <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <div class="common-screen-container">
      <div class="common-input-container">
        <span>销售机会名称:</span>
        <el-input
          class="common-screen-input"
          placeholder="请输入"
          v-model="params.name"
        ></el-input>
      </div>
      <div class="common-input-container">
        <span>客户名称:</span>
        <el-input
          class="common-screen-input"
          placeholder="请输入"
          v-model="params.customerName"
        ></el-input>
      </div>
      <div class="common-input-container">
        <span>客户级别:</span>
        <el-select
          class="common-screen-input"
          @change="commonFun"
          v-model="params.typeId"
          placeholder="请选择"
          filterable
          clearable
        >
          <el-option
            v-for="item in allDict['1']"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
       <div class="common-input-container">
        <span>客户划分:</span>
        <el-select class="common-screen-input" v-model="params.projectType" @change="commonFun" placeholder="请选择" filterable clearable>
          <el-option label="项目" :value="217"> </el-option>
          <el-option label="散号" :value="218"> </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>业务类型:</span>
        <el-select
          class="common-screen-input"
          @change="commonFun"
          v-model="params.type"
          placeholder="请选择"
          filterable
          clearable
        >
          <el-option
            v-for="item in allDict['12']"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>销售阶段:</span>
        <el-select
          class="common-screen-input"
          v-model="params.stage"
          @change="commonFun"
          placeholder="请选择"
          filterable
          clearable
        >
          <el-option
            v-for="item in allDict['4']"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>客户负责人:</span>
        <el-select
          class="common-screen-input"
          v-model="params.createId"
          @change="commonFun"
          filterable
          clearable
          placeholder="请选择"
        >
          <el-option
            v-for="item in responsible"
            :key="item.id"
            :label="item.nickName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>机会状态:</span>
        <el-select
          class="common-screen-input"
          v-model="params.state"
          @change="commonFun"
          filterable
          clearable
          placeholder="请选择"
        >
          <el-option key="0" label="跟进中" value="0"></el-option>
          <el-option key="1" label="已成单" value="1"></el-option>
          <el-option key="2" label="已流失" value="2"></el-option>
        </el-select>
      </div>
      <!-- <div class="common-input-container">
        <span class="time">创建时间:</span>
        <el-date-picker
          v-model="params.createTime"
          @change="commonFun"
          type="daterange"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </div> -->
      <div class="common-input-container">
        <common-date @commonGetDate="commonGetDate" ref="commonReset"></common-date>
      </div>
      <div class="common-input-container">
        <!-- <el-button class="common-screen-btn" type="primary" @click="() => getChanceList()">查 询</el-button> -->
        <el-button class="common-screen-btn" type="primary" @click="() => commonFun()">查 询</el-button>
        <el-button class="common-screen-btn" plain @click="() => resetBtn()">重 置</el-button>
      </div>
    </div>
    <div class="gap"></div>

    <!-- 所有  我的 -->
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane v-if="rolesName=='公司管理员'||rolesName=='销售主管'||rolesName=='超级管理员'" label="所有销售机会" name="first">
        <el-table class="common-table-haveborder common-table-bgGrey th" :data="tableData" fit border>
      <el-table-column
        prop="name"
        label="销售机会名称"
        :formatter="tableColumn"
      >
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="() => chanceDetail(scope.row)">{{scope.row.name}}</el-button>
        </template>
      </el-table-column>
      <el-table-column
        prop="customer_name"
        label="客户名称"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column prop="state" label="机会状态">
        <template slot-scope="scope">
          <span class="common-tableStatus-green-bgc" v-if="scope.row.state == 0">跟进中</span>
          <span class="common-tableStatus-blue-bgc" v-if="scope.row.state == 1">已成单</span>
          <span class="common-tableStatus-grey-bgc" v-if="scope.row.state == 2">已流失</span>
        </template>
      </el-table-column>
       <el-table-column
        prop="project_type"
        label="客户划分"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="typeName"
        label="业务类型"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="level_name"
        label="客户级别"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="price"
        label="销售机会金额"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="stage_name"
        label="销售机会阶段"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column prop="expected_time" label="预计成单日期">
        <template slot-scope="scope">
          <span>
            {{
              scope.row.expected_time
                ? DateTransform(scope.row.expected_time)
                : "--"
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="manager"
        label="客户负责人"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="remark"
        label="备注"
        :formatter="tableColumn"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="createName"
        label="创建人"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="create_time"
        label="创建时间"
        :formatter="tableColumn"
        width="100"
      ></el-table-column>
        </el-table>
        <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.pageNum"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
        </el-pagination>
      </el-tab-pane>
      <el-tab-pane label="我的销售机会" name="second">
        <el-table class="common-table-haveborder common-table-bgGrey th" :data="tableData" fit border>
      <el-table-column
        prop="name"
        label="销售机会名称"
        :formatter="tableColumn"
      >
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="() => chanceDetail(scope.row)">{{scope.row.name}}</el-button>
        </template>
      </el-table-column>
      <el-table-column
        prop="customer_name"
        label="客户名称"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column prop="state" label="机会状态">
        <template slot-scope="scope">
          <span class="common-tableStatus-green-bgc" v-if="scope.row.state == 0">跟进中</span>
          <span class="common-tableStatus-blue-bgc" v-if="scope.row.state == 1">已成单</span>
          <span class="common-tableStatus-grey-bgc" v-if="scope.row.state == 2">已流失</span>
        </template>
      </el-table-column>
       <el-table-column
        prop="project_type"
        label="客户划分"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="typeName"
        label="业务类型"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="level_name"
        label="客户级别"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="price"
        label="销售机会金额"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="stage_name"
        label="销售机会阶段"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column prop="expected_time" label="预计成单日期">
        <template slot-scope="scope">
          <span>
            {{
              scope.row.expected_time
                ? DateTransform(scope.row.expected_time)
                : "--"
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="manager"
        label="客户负责人"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="remark"
        label="备注"
        :formatter="tableColumn"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="createName"
        label="创建人"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="create_time"
        label="创建时间"
        :formatter="tableColumn"
        width="100"
      ></el-table-column>
        </el-table>
        <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.pageNum"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
        </el-pagination>
      </el-tab-pane>
    </el-tabs>

    
    <el-dialog
      v-dialogDrag
      title="编辑销售机会"
      :append-to-body="true"
      :visible.sync="dialogVisible"
      width="520px"
    >
      <div>
        <el-form
          :model="chanceForm"
          :rules="chanceRules"
          ref="chanceForm"
          label-width="130px"
          class="demo-ruleForm"
        >
          <el-form-item label="销售机会名称" prop="name">
            <el-input
              class="dialog-input"
              v-model="chanceForm.name"
              maxLength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="客户名称" prop="custName">
            <el-input
              class="dialog-input"
              :value="chanceForm.custName"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="销售机会金额" prop="price">
            <el-input
              type="number"
              class="dialog-input"
              v-model="chanceForm.price"
              maxLength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="销售阶段" prop="stage">
            <el-select
              class="dialog-input"
              v-model="chanceForm.stage"
              placeholder="请选择"
              filterable
            >
              <el-option
                v-for="item in allDict['4']"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="预计成单时间" prop="expectedTime">
            <el-date-picker
              class="dialog-input"
              v-model="chanceForm.expectedTime"
              type="date"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input
              class="dialog-input"
              type="textarea"
              v-model="chanceForm.remark"
              maxLength="500"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="dialogVisible = false"
          >取 消</el-button
        >
        <el-button
          class="common-screen-btn"
          type="primary"
          @click="() => subChanceForm('chanceForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <sales-leads-detail
      ref="saleLead"
      :drawer="drawer"
      :assignPeople="assignPeople"
      :tableRow="tableRow"
      :adminInfo="adminInfo"
      :chanceInfo="chanceInfo"
      :cusInfo="cusInfo"
      :chanceFollowList="chanceFollowList"
      :stageTaskList="stageTaskList"
      :feedbackList="feedbackList"
      :nextStage="nextStage"
      @handleClose="handleClose"
      @chanceDetail="chanceDetail"
      @loadFun="loadFun"
      :allDict="allDict"
      :title="'销售机会'"
      :loading="loading"
    ></sales-leads-detail>

    <!-- 头部报表弹 -->
    <header-dialog
      ref="homeChild"
      :methodFuc="salesHead"
      :comId.sync="this.params.companyId"
      :headerType="headerType"
      :dialogTit="dialogTit"
      :dialogVisible="dialogVisible2"
      :paramsTit="paramsTit"
      :childColName="childColName"
      @handleClose="handleClose2"
    ></header-dialog>
  </div>
</template>
<script>
import Breadcrumb from "../../components/common/breadcrumb.vue";
import SalesLeadsDetail from "./children/salesLeadsDetail.vue";
import { getChanceList, chanceDetail, deleteChance, editChance,salesHead } from "../../service/customer.js";
import { getRoleByType, commonDict } from "../../service/common.js";
import { tableColumn, DateTransform,Config } from "../../utils/index.js";
import CommonDate from "../../components/common/commonDate.vue"
import headerDialog from "../../components/common/commonHeaderDialog.vue";
export default {
  components: { Breadcrumb, SalesLeadsDetail, CommonDate,headerDialog },
  data() {
    return {
      salesHead,
      loading: false,
      tableColumn,
      DateTransform,
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "营销", isLink: false },
        { title: "销售", isLink: false },
        { title: "销售机会", isLink: false }
      ],
      tableRow: "", // 客户id用于编辑/查看
      dialogVisible: false, // 修改密码员工弹窗
      adminInfo: "",
      chanceInfo: "",
      cusInfo: "",
      chanceFollowList: "",
      stageTaskList: "",
      feedbackList: "",
      nextStage: false,
      pagination: {
        // 分页参数
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      params: {
        // 列表的传参
        pageNum: 1,
        pageSize: 10,
      },
      custDict: {},
      chanceForm: {},
      chanceRules: {
        name: [
          { required: true, message: "请填写客户名称", trigger: "change" }
        ],
        custName: [{ required: true, trigger: "change" }],
        price: [
          { required: true, message: "请填写销售机会金额", trigger: "change" }
        ],
        stage: [
          { required: true, message: "请选择销售阶段", trigger: "change" }
        ]
      },
      allDict: {"1": [], "4": [], "12": []},
      drawer: false, // 新增员工弹窗
      responsible: [], // 负责人下拉
      assignPeople: [], // 子组件指派人下拉
      tableData: [],
      totalNum: 0,
      todayNum: 0,
      yesterdayNew: 0,
      weekNew: 0,
      monthNum: 0,
      NotSingle: 0,
      dayOutTime: 0,
      rolesCompany: this.$sto.get(Config.constants.userInfo).roles_company, // 所属公司下拉
      companyId: this.$sto.get(Config.constants.userInfo).company_id,
      //////////////////////////
      headerType: "",
      dialogTit: "",
      dialogVisible2: false,
      paramsTit: [],
      childColName: [],
      topDate: 1,
      rolesName: this.$sto.get(Config.constants.userInfo).roles[0],
      activeName: 'first'
    };
  },
  created() {
    if (this.rolesCompany) {
      this.params.companyId = this.rolesCompany[0].id;
    }
    if (this.$route.query.idName) {
      let newArr = this.$route.query.idName.split('?*?')
      this.params.name = newArr[1]
      if(this.rolesName=='公司管理员'||this.rolesName=='销售主管'||this.rolesName=='超级管理员'){
      this.getChanceList(newArr);
      }else{
        this.activeName = 'second'
        this.params.createId = this.$sto.get(Config.constants.userInfo).user_id
        this.getChanceList(newArr);
      }
      // this.getChanceList(newArr);
    } else {
      if (this.$route.query.name && this.$route.query.id && this.$route.query.id != 'undefined' && this.$route.query.customerId) {
        this.params.name = this.$route.query.name
        this.chanceDetail({ id: this.$route.query.id, customerId: this.$route.query.customerId })
      }
      if(this.rolesName=='公司管理员'||this.rolesName=='销售主管'||this.rolesName=='超级管理员'){
      this.getChanceList();
      }else{
        this.activeName = 'second'
        this.params.createId = this.$sto.get(Config.constants.userInfo).user_id
        this.getChanceList();
      }
    }
    
    this.getRoleByType(0);
    this.getRoleByType(2);
    this.commonDict(4);
    this.commonDict(1);
    this.commonDict(12);
  },
  methods: {
    commonGetDate (startTime, endTime) {
      this.params.startTime = startTime
      this.params.endTime = endTime
      this.getChanceList()
    },
    loadFun(value) {
      this.loading = value
    },
    commonFun() {
      this.params.pageNum = 1;
      this.params.pageSize = 10;
      this.getChanceList();
    },
    subChanceForm(formName) {
      // 新增/编辑用户确认
      let chanceForm = { ...this.chanceForm };
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (chanceForm.custName) {
            delete chanceForm.custName;
          }
          await editChance(chanceForm, chanceForm.id);
          this.$message.success("编辑成功");
          this.resetBtn();
          this.getChanceList();
          this.dialogVisible = false;
        } else {
          return false;
        }
      });
    },
    async deleteChance(id) {
      await deleteChance({}, id);
      this.$message.success("删除成功");
      this.getChanceList();
    },
    async chanceDetail(row) {
      // 获取机会详情
      this.loading = true
      let resData = (await chanceDetail({ customerId: row.customer_id, chanceId: row.id},)).data;
      this.drawer = true;
      if (resData && resData.stageTaskList) {
        let nextStage = true;
        let stageTask = [...resData.stageTaskList];
        stageTask.map((item) => {
          if ((resData.cmsChanceDto.stage == 13 && item.groupTaskId == 1) || (resData.cmsChanceDto.stage == 14 && item.groupTaskId == 6) || (resData.cmsChanceDto.stage == 15 && item.groupTaskId == 10)) {
            if (item.chanceState == "0") {
              nextStage = false;
            }
          }
        });
        this.nextStage = nextStage;
      }
      // if (resData.list && resData.list.length) {
      //   resData.list.map((item) => {
      //     item.value = JSON.parse(item.value)
      //   })
      // }
      this.$refs["saleLead"].getNowStage(row);
      this.tableRow = row;
      this.adminInfo = resData.admin || "";
      this.chanceInfo = resData.cmsChanceDto || "";
      this.cusInfo = resData.customer || "";
      // this.chanceFollowList = resData.list || "";
      this.stageTaskList = resData.stageTaskList || "";
      this.feedbackList = resData.feedbackList || "";
      this.$refs["saleLead"].chanceNextPlanList(row.id, row.customer_id)
      this.$refs["saleLead"].planTaskList(row.id, row.customer_id)
      this.$refs["saleLead"].workLog(row.id)
      this.loading = false
    },
    resetBtn() {
      // 重置
      this.$refs.commonReset.resetFun()
      this.params = {
        pageNum: 1,
        pageSize: 10,
        companyId: this.rolesCompany[0].id
      };
      if(this.activeName == 'second'){
        this.params.createId = this.$sto.get(Config.constants.userInfo).user_id
      }
      this.topDate = 1
      this.getChanceList();
    },
    async commonDict(type) {
      // 字典类型 0所有 1客户级别 2客户来源 3公海关系 4销售阶段 5订单状态 6所属行业
      let resData = (await commonDict({}, type)).data;
      this.allDict[`${type}`] = resData;
    },
    async getRoleByType(id) {
      let resData = (await getRoleByType({companyId: this.companyId,}, id)).data;
      if (id == 0) {
        this.responsible = resData;
      } else if (id == 2) {
        this.assignPeople = resData;
      }
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.pageNum = e;
      }
      this.getChanceList();
    },
    async getChanceList(idname) {
      // 销售机会列表
      this.loading = true;
      let params = { ...this.params };
      let resData = (await getChanceList(params)).data;
      this.pagination.total = resData.total;
      this.pagination.pageSize = resData.size;
      this.tableData = resData.records;
      this.todayNum = resData.today;
      this.totalNum = resData.totalNum;
      this.monthNum = resData.month;
      this.yesterdayNew = resData.yesterday;
      this.weekNew = resData.week;
      this.NotSingle = resData.NotSingle;
      this.dayOutTime = resData.dayOutTime;
      this.loading = false;
      if (idname && resData.records) {
        resData.records.map((item) => {
          if (item.id == idname[0]) {
            this.chanceDetail(item)
          }
        })
      }
    },
    addEdit(row) {
      // 修改机会弹窗
      if (row) {
        this.chanceForm = {
          id: row.id,
          custName: row.customer_name,
          name: row.name,
          price: row.price,
          stage: row.stage,
          expectedTime: row.expected_time,
          remark: row.remark
        };
      }
      this.dialogVisible = true;
    },
    handleClose() {
      this.getChanceList();
      this.drawer = false;
      // 关闭新增员工弹窗
      // done();
    },
    addDrawer(row) {
      //新增员工
      this.drawer = true;
      if (row) {
        this.tableRow = row;
      }
    },
    deleteItem() {},
    companyFun() {
      //选择公司筛选
      this.getChanceList();
    },
    /////////////////////////////////////////////////
    collect(type, name, screen, arr) {
      this.dialogTit = name;
      this.headerType = type;
      this.paramsTit = screen;
      this.childColName = arr;
      this.dialogVisible2 = true;
      this.$refs.homeChild.getHeaderList(type,this.params.companyId);
    },
    handleClose2() {
      this.dialogVisible2 = false
    },
    handleClick(tab) {
      if(tab.name == 'second'){
        this.params.createId = this.$sto.get(Config.constants.userInfo).user_id
        this.getChanceList()
      }else{
        delete this.params.createId
        this.getChanceList()
      }
    }
  }
};
</script>
<style lang="less" scoped>
.salesLeads-container {
  .common-screen-container {
    padding-left: 32px;
  }
  /deep/ .el-button {
    white-space: normal;
    text-align: left;
    line-height: 20px;
  }
  /deep/ .breadcrumb-container{
    padding: 0 32px;
  }
  /deep/ .el-tabs__content{
    padding: 0 32px;
  }
  text-align: left;
  .nameIcon {
    width: 32px;
    height: 32px;
    color: #f56a00;
    background-color: #fde3cf;
    border-radius: 50%;
    text-align: center;
    line-height: 32px;
  }
}
.dialog-input {
  width: 320px !important;
}
.time {
  margin-right: 16px;
}
.tit-img {
  margin-left: 3px;
  margin-top: 2px;
  cursor: pointer;
}
.ml{
  margin-left: 50px;
}
/deep/ .el-table--border::after, 
.el-table--group::after{
  width: 0 !important;
}
.gap{
  position: absolute;
  left: 0;
  right: 0;
  height: 16px;
  background-color: #ededed;
}
.el-tabs {
  margin-top: 32px;
}
.title-container {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 20px 32px;
  align-items: center;
  position: relative;
  &::after {
    content: " ";
    background: #e6e6e6;
    width: 120%;
    height: 1px;
    position: absolute;
    left: -10%;
    bottom: -1px;
    z-index: 999999;
  }
  div {
    font-size: 24px;
    color: #101010;
  }
}
</style>
